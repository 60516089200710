<!--
 * @Author: DY
 * @Date: 2021-03-16 16:03:00
 * @LastEditTime: 2021-03-19 11:07:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\salesBusiness\contractAccount\contractTypeConversion.vue
-->
<template>
    <div class="contractTypeConversion">
        <div>
            <h4>自定义合同编号：</h4>
            <el-input placeholder="请输入自定义编号" v-model="customMumber">
                <template slot="prepend">
                    【{{ numberPrefix }}】
                </template>
            </el-input>
        </div>
        <footer>
            <el-button @click="close">
                取消
            </el-button>
            <el-button type="primary" @click="confirm">
                确认
            </el-button>
        </footer>
    </div>
</template>

<script>
export default {
    // 合同类型转换
    name: 'contract-type-conversion',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            // 自定义编号前缀
            numberPrefix: '',
            // 自定义编号
            customMumber: '',
        };
    },
    computed: {},
    watch: {},
    created() {
        // 自定义编号前缀
        this.numberPrefix = this.$takeTokenParameters('TenantCode');
    },
    mounted() {},
    destroyed() {},
    methods: {
        close() {
            this.$parent.hide();
        },
        confirm() {
            if (this.customMumber !== '') {
                const neewNumber = '【' + this.numberPrefix + '】' + this.customMumber;
                const callback = this.extr;
                window[callback.functionName](neewNumber, callback.thisObj, callback.tableData);
                this.$parent.hide();
            } else {
                this.$message('请先填写自定义编号！');
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.contractTypeConversion
    height 100%
    padding 0.2rem
    h4
        font-size 0.16rem
        line-height 0.16rem
        margin-bottom 0.2rem
    footer
        text-align center
        position absolute
        bottom 0.3rem
        left 0
        width 100%
        button
            width 1.84rem
            height 0.44rem
            cursor pointer
            margin 0 0.2rem
</style>